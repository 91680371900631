import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { UsersFilter } from "./users-filter/UsersFilter";
import { UsersTable } from "./users-table/UsersTable";
import { UsersGrouping } from "./users-grouping/UsersGrouping";
import { useUsersUIContext } from "./UsersUIContext";
import * as XLSX from 'xlsx';
import axios from "../../../axios";
import { useSnackbar } from "notistack";

export function UsersCard() {
  const {enqueueSnackbar} = useSnackbar();
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      ids: usersUIContext.ids,
      newUserButtonClick: usersUIContext.newUserButtonClick,
    };
  }, [usersUIContext]);

  const exportClickHandler =async ()=>{
    try{
      const res = await axios.get("/users/getAllUsers")
      const data = res?.data?.payload
      if(data?.length === 0){
        enqueueSnackbar("Student data is not available!", {
          variant: "error",
        });
      }
      handleDownload(data)
    }catch(err){
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    }
  }

  const handleDownload = (data) => {
    const formattedData = data.map((item) => ({
      Id : item.id,
      name: item.name,
      email: item.email,
      mobile: item.mobile,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');

    XLSX.writeFile(wb, 'users_data.xlsx');
    enqueueSnackbar("Student record download sucessfully", {
      variant: "success",
    });
  };


  return (
    <Card>
      <CardHeader title="Students list">
        <CardHeaderToolbar>
          <div style={{display : 'flex' , gap : '20px'}}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={()=>{
              exportClickHandler()
            }}
          >
            Export 
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={usersUIProps.newUserButtonClick}
          >
            New Student
          </button>
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <UsersFilter />
        {usersUIProps.ids.length > 0 && <UsersGrouping />}
        <UsersTable />
      </CardBody>
    </Card>
  );
}
